<template>
  <div>
    <b-row>
      <b-col cols="12" lg="6">
        <b-card>
          <div class="form-group">
            <label for="start-date">End Date:</label>
            <div class="d-flex align-items-center">
              <flat-pickr
                id="end-date"
                v-model="filter.end_date"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d', maxDate: 'today' }"
              />
              <button
                :disabled="filter.end_date === moment().format('YYYY-MM-DD')"
                @click="filter.end_date = moment().format('YYYY-MM-DD')"
                class="btn btn-outline-secondary ml-2"
              >
                Reset
              </button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="3">
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h4 class="mb-1">{{ !isLoading ? graphicContent.user_count : 'Counting..' }}</h4>
              <p class="mb-0">Total Users</p>
            </div>
            <users-icon size="3x" class="custom-class"></users-icon>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" lg="3">
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h4 class="mb-1">{{ !isLoading ? graphicContent.article_count : 'Counting..' }}</h4>
              <p class="mb-0">Total Articles</p>
            </div>
            <file-text-icon size="3x" class="custom-class"></file-text-icon>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" lg="3">
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h4 class="mb-1">{{ !isLoading ? graphicContent.webinar_count : 'Counting..' }}</h4>
              <p class="mb-0">Total Webinars</p>
            </div>
            <star-icon size="3x" class="custom-class"></star-icon>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" lg="3">
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h4 class="mb-1">{{ !isLoading ? graphicContent.competition_count : 'Counting..' }}</h4>
              <p class="mb-0">Total Competitions</p>
            </div>
            <instagram-icon size="3x" class="custom-class"></instagram-icon>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card title="Last 7 Days User Growth">
      <apexcharts
        v-if="!isLoading"
        width="100%"
        height="350"
        type="line"
        :options="chartOptionsUser"
        :series="seriesUser"
      ></apexcharts>
      <div class="text-center text-secondary my-2" v-else>
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </b-card>
    <b-card title="Last 7 Days Article Growth">
      <apexcharts
        v-if="!isLoading"
        width="100%"
        height="350"
        type="bar"
        :options="chartOptionsArticle"
        :series="seriesArticle"
      ></apexcharts>
      <div class="text-center text-secondary my-2" v-else>
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  UsersIcon,
  FileTextIcon,
  StarIcon,
  InstagramIcon,
} from "vue-feather-icons";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import _ from "lodash";
import { BCard, BCardText, BLink, BRow, BCol, BSpinner } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    flatPickr,
    BSpinner,
    UsersIcon,
    FileTextIcon,
    StarIcon,
    InstagramIcon,
    apexcharts: VueApexCharts,
  },

  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },

  data() {
    return {
      moment,
      graphicContent: {},
      isLoading: false,
      userStatisticsOfWeek: [],
      filter: {
        end_date: moment().format("YYYY-MM-DD"),
      },
      chartOptionsUser: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesUser: [
        {
          name: "Users",
          data: [],
        },
      ],
      chartOptionsArticle: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesArticle: [
        {
          name: "Articles",
          data: [],
        },
      ],
    };
  },

  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$http
        .get("/api/v1/admin/graphic-content", {
          params: this.filter,
        })
        .then((response) => {
          this.graphicContent = response.data.data;
          const categoriesUser = [];
          const seriesUser = [];
          const categoriesArticle = [];
          const seriesArticle = [];
          this.graphicContent.user_statistics_of_week.forEach((item) => {
            categoriesUser.push(item.date);
            seriesUser.push(item.count);
          });
          this.graphicContent.article_statistics_of_week.forEach((item) => {
            categoriesArticle.push(item.date);
            seriesArticle.push(item.count);
          });
          this.chartOptionsUser.xaxis.categories = categoriesUser;
          this.seriesUser[0].data = seriesUser;
          this.chartOptionsArticle.xaxis.categories = categoriesArticle;
          this.seriesArticle[0].data = seriesArticle;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
